<template>
  <div class="booklist" :style="{ minHeight: $store.state.clientHeight }">
    <div class="page-badge">
      <div>
        <i class="iconfont iconshouye"></i>
        <span class="on">报刊订阅&nbsp;>&nbsp;</span>
        <span>{{ curName }}</span>
      </div>
    </div>
    <div class="page-content">
      <div class="list-container" v-if="outLine != 2">
        <div class="list-header clear">
          <span class="w35">刊物名称</span>
          <span class="w35">适用说明</span>
          <span class="w15">价格</span>
          <span class="w15">管理</span>
        </div>
        <div class="list-mainer">
          <div
            class="list-item clear"
            v-for="(item, index) in booklist"
            :key="item.id"
          >
            <div
              :class="['item-detail', 'w35', { default: type == 6 }]"
              @click="toDetail(item.book_no)"
            >
              <div class="item-img"><img :src="item.picture" alt="" /></div>
              <div class="item-msg">
                <p>{{ item.name.slice(0, 20) }}</p>
                <span :title="item.press">{{ item.press }}</span>
                <div>
                  <span
                    class="item-type"
                    v-for="(type, index) in item.book_type"
                    :key="index"
                    >{{ type }}</span
                  >
                </div>
              </div>
            </div>
            <div class="item-text w35">
              <span>{{ item.apply_explain }}</span>
            </div>
            <div class="item-allPrice w15">
              <div class="item-price-box">
                <span>单价：{{ item.unit_price }}元/期</span>
                <span
                  >[{{ item.frequency_name }}/共{{
                    item.frequency_periods
                  }}期]</span
                >
                <p>
                  总价：<span>{{ item.half_year_price }}元</span>
                </p>
              </div>
            </div>
            <div class="item-operate w15" v-if="type != 6">
              <div
                @click="addCart(item, index)"
                :class="['add-btn', 'btn-lingred', { add: item.is_cart == 1 }]"
              >
                <span>{{ item.is_cart == 0 ? '加入购物车' : '已加入' }}</span>
              </div>
              <div class="buy-btn com-btn" @click="buyNow(item)">立即购买</div>
            </div>
            <div class="item-operate w15" v-if="type == 6">
              <div
                class="buy-btn com-btn change-btn"
                @click="changeBook(item.book_no)"
              >
                确认更换
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 暂无数据 -->
      <noData
        noDataText="暂无书籍"
        v-if="
          noData &&
          outLine != 2 &&
          ((gradeId != 'kong' && type < 4) || type > 3)
        "
      ></noData>
      <!-- 征订结束 -->
      <div class="out-line" v-if="outLine == 2">
        本次订阅季已结束，已有订单用户可查看、打印订单详情，或开具发票。<br />您的订单会在新学期开学后发放。感谢您的订阅，欢迎您下个订阅季继续订阅！<br />订单问题请在微信公众号留言，我们会尽快回复您
        (工作日时间) !
      </div>
      <!-- 筛选提示 -->
      <div
        class="select-tips"
        v-if="gradeId == 'kong' && outLine != 2 && isLog && type < 4"
      >
        <div>温馨提示</div>
        <i class="el-icon-back" style="font-weight: 900; font-size: 30px"></i>
        请您先在左侧边栏选择您的孩子<span style="color: #dd0000">2023年9月</span
        >秋季开学时所上的年级。
      </div>
      <!-- 分页器 -->
      <div class="list-pager" v-if="total > 0 && showPager && outLine != 2">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="pageIndex"
          :total="total"
          :page-size="pageSize"
          @current-change="changePage"
        ></el-pagination>
      </div>
    </div>

    <!-- 筛选框 -->
    <screenBox
      v-if="type < 5"
      :gradeId="gradeId"
      :gradeList="gradeList"
      @requestList="screenList"
    ></screenBox>
  </div>
</template>

<script>
import noData from '@/components/noData.vue'
import screenBox from '@/components/SelectGrade.vue'
import bookImg from '@/assets/images/1.png'
let that
export default {
  name: 'booklist',
  components: {
    noData,
    screenBox,
  },
  data: function () {
    return {
      curName: '小学目录', // 导航标题
      total: 0, // 列表总数量
      noData: false, // 是否没有数据
      ifAll: false, // 是否加载全部
      pageIndex: 1, // 当前列表页
      pageSize: 10, // 每页数量
      curType: 0, // 当前页面类型
      booklist: [], // 报刊列表
      searchText: '', // 搜索内容
      showPager: false, // 展示分页器
      outLine: sessionStorage['outLine'], // 征订结束
      gradeList: [], // 年级列表
      gradeId: 0, // 筛选年级Id
      isLog: sessionStorage['ifLogin'], // 是否登录
    }
  },
  props: {
    // 2:小学目录;3:中学目录;4:幼教目录;5:搜索目录;6:更换书籍
    type: {
      type: String,
      default: '2',
    },
    search: {
      type: String,
      default: '',
    },
  },
  created() {
    that = this
  },
  mounted() {
    // 判断页面类型
    this.showPager = false
    // 首次需提示左侧选择
    // that.gradeId = that.isLog ? (sessionStorage['selectGrade'] == 1 ? sessionStorage['gradeId'] : 'kong') : 0;
    that.gradeId = that.isLog && that.$route.query.navId < '4' ? 'kong' : 0
    if (that.$route.query.navId < '6') {
      that.getData(that.$route.query.navId)
    } else if (that.$route.query.navId == '6') {
      that.getChangeBooks()
    }
    this.$store.commit('updateH', 130)
  },
  beforeDestroy() {
    sessionStorage['gradeId'] = 0
  },
  watch: {
    type: (newId, oldId) => {
      that.showPager = false
      that.pageIndex = 1
      // that.gradeId = that.isLog ? (sessionStorage['selectGrade'] == 1 ? 0 : 'kong') : 0;
      that.gradeId = that.isLog && newId < 4 ? 'kong' : 0
      if (oldId) {
        that.getData(newId)
      }
      that.curType = newId
      that.noData = false
      that.booklist = []
      switch (newId) {
        case '2':
          that.curName = '小学目录'
          break
        case '3':
          that.curName = '中学目录'
          break
        case '4':
          that.curName = '学校教师'
          break
        case '5':
          that.curName = '搜索结果'
          break
        case '6':
          that.curName = '更换书籍'
          break
      }
    },
    search: (newValue, oldValue) => {
      if (that.type == 5 && newValue != '') {
        that.searchBooks()
      }
    },
  },
  methods: {
    // 请求列表数据
    getData: function (e) {
      if (e < 5) {
        // 报刊列表
        that
          .$http({
            url: 'book/index',
            method: 'GET',
            showLoading: true,
            params: {
              city_id: sessionStorage.getItem('city'),
              xz_type: sessionStorage.getItem('xzType'),
              grade_id: that.gradeId,
              is_cart_count: true,
              book_type_id: e - 1,
              page: that.pageIndex,
              limit: that.pageSize,
            },
          })
          .then((res) => {
            that.noData = res.result.count > 0 ? false : true
            that.booklist = res.result.data
            that.total = res.result.count
            sessionStorage['cartMount'] = res.result.cart_count
            this.showPager = true
            // 年级筛选列表
            this.gradeList = res.result.grade_list
            that.$emit('init')
          })
          .catch((err) => {
            console.error(err)
          })
      } else if (e == 5) {
        that.searchBooks()
      } else if (e == 6) {
        that.getChangeBooks()
      }
      this.$emit('init', 'search') // 清空搜索框
    },
    // 点击详情
    toDetail: function (e) {
      if (this.type < 6) {
        this.$router.push({
          path: '/detail',
          query: { id: e, type: that.$route.query.navId },
        })
      }
    },
    // 搜索内容
    searchBooks: function () {
      if (!that.search) {
        that.$message.error('请输入搜索内容')
        return false
      }
      this.$http({
        url: 'book/search',
        method: 'GET',
        showLoading: true,
        params: {
          city_id: sessionStorage['city'],
          xz_type: sessionStorage['xzType'],
          is_cart_count: 1,
          search: that.search,
          page: that.pageIndex,
          limit: that.pageSize,
        },
      })
        .then((res) => {
          that.total = res.result.count
          that.noData = res.result.count > 0 ? false : true
          that.booklist = res.result.data
          this.showPager = true
        })
        .catch((err) => {
          console.error(err)
        })
    },
    // 点击更换书籍
    changeBook: function (e) {
      that
        .$http({
          url: 'order/edit_book',
          showLoading: true,
          data: {
            order_no: that.$route.query.order_no,
            old_book_no: that.$route.query.book_no,
            new_book_no: e,
          },
        })
        .then((res) => {
          that.$message.success('更换成功')
          setTimeout(() => {
            that.$router.replace({ path: '/orders' })
          }, 1000)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    // 点击分页
    changePage: function (e) {
      that.pageIndex = e
      if (that.type < 5) {
        that.getData(that.type)
      } else if (that.type == 5) {
        that.searchBooks()
      } else {
        that.getChangeBooks()
      }
    },
    // 点击加入购物车
    addCart: function (e, i) {
      if (e.is_cart != 0) {
        return false
        that.$message.error('报刊已经在购物车了')
      }
      if (sessionStorage['ifLogin']) {
        that
          .$http({
            url: 'shop_cart/add',
            data: {
              city_id: sessionStorage['city'],
              xz_type: sessionStorage['xzType'],
              book_no: e.book_no,
              count: 1,
              is_cart_count: 1,
            },
          })
          .then((res) => {
            that.$message.success('添加成功')
            that.booklist[i].is_cart = 1
            sessionStorage['cartMount'] = res.result.cart_count
            that.$emit('init')
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        that.$emit('login')
      }
    },
    // 点击立即购买
    buyNow: function (e) {
      e.count = 1
      sessionStorage['orderList'] = JSON.stringify([e])
      sessionStorage.removeItem('addressMsg')
      if (sessionStorage['ifLogin']) {
        that.$router.push('/confirm')
      } else {
        that.$emit('login')
      }
    },
    // 获取更换书籍列表
    getChangeBooks: function () {
      that
        .$http({
          url: 'order/get_edit_book',
          method: 'GET',
          params: {
            order_no: that.$route.query.order_no,
            book_no: that.$route.query.book_no,
            page: that.pageIndex,
            limit: that.pageSize,
          },
        })
        .then((res) => {
          that.total = res.result.count
          that.noData = res.result.count > 0 ? false : true
          that.booklist = res.result.rows
          this.showPager = true
        })
        .catch((err) => {
          if (err.code == 500) {
            that.$emit('login')
          }
        })
    },

    // ==============================================================================
    // 筛选结果请求列表
    // ==============================================================================
    screenList: function (e) {
      if (this.gradeId != e) {
        this.pageIndex = 1
        this.gradeId = e
        sessionStorage['gradeId'] = e
        this.getData(this.type)
      }
    },
  },
}
</script>

<style>
/* 面包屑 */
.page-badge {
  width: 100%;
  border-bottom: 1px solid rgb(217, 217, 217);
}
.page-badge > div {
  width: 1300px;
  height: 60px;
  display: block;
  margin: 0 auto;
}
.page-badge > div i {
  color: #3b77d1;
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.page-badge > div span {
  font-size: 16px;
  line-height: 60px;
  display: inline-block;
  vertical-align: middle;
  color: #aaaaaa;
}
.page-badge > div span.on {
  color: #3b77d1;
}
.booklist {
  background: rgb(244, 244, 244);
  padding-top: 90px;
}
.booklist .list-item > div {
  padding: 0 15px;
}
.booklist .list-item .item-detail.default {
  cursor: default;
}
.booklist .page-content .change-btn {
  margin-top: 60px !important;
}
.booklist .item-allPrice {
  position: relative;
}
.booklist .item-allPrice .item-price-box {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.booklist .item-allPrice .item-price-box > span {
  color: #777777;
  font-size: 14px;
  font-weight: 500;
}
.booklist .item-allPrice .item-price-box > p {
  text-align: center;
}
.booklist .item-allPrice .item-price-box > p span {
  display: inline-block;
  width: auto;
}
</style>
